import React, { useEffect } from 'react';
import Table from '../components/Table';
import Pagination from '../components/Pagination';
import { getPlayers } from '../services/PlayerService';
import { getTotalPlayer } from '../services/StatService';
import { useSelector, useDispatch } from 'react-redux';
import { setPlayers, setPagination } from '../store/players';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import TableNotFound from '../components/TableNotFound';
export default function Players() {
  const { user } = useSelector((state) => state.auth);
  const { page } = useParams();
  const dispatch = useDispatch();
  async function getPlayersData() {
    console.log("getPlayersData", page)
    console.log('isNaN(page)',isNaN(page))
    const limit = 20;
    if (isNaN(page)) {
      return;
    }
    const players = await getPlayers(user, parseInt(page), limit);
    if (players.status && players.data !== null) {
      //totalplayer data
      const data  = await getTotalPlayer(user);
      console.log("getTotalPlayer",data)
      dispatch(setPlayers(players.data));
      dispatch(
        setPagination({ page: parseInt(page), limit, totalPlayer: data.count })
      );
      toast.success(
        players?.data?.length + ' Adet Veri başarılı bir şekilde getirildi!'
      );
    } else {
      toast.error('Getirilecek veri bulunamadı!');
    }
    return players;
  }
  
  useEffect(() => {
    getPlayersData();
  }, [page]);
  return (
    <>
      {/* <PageHeader /> */}
      <main className='flex-1 pb-8'>
        <div>
          {isNaN(parseInt(page)) ? <>
            <TableNotFound />
          </> : (
            <>

              <Table title={'Oyuncular'} placeholder={'Oyuncu Ara'} />
              <Pagination currentPage={isNaN(page) ? 1 : parseInt(page)} />
            </>
          )}
        </div>

        <div className='mt-8'>
          <div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8'>
            {/* <Stats /> */}
          </div>
        </div>
      </main>
    </>
  );
}

import React from 'react'
import Tabs from '../components/Tabs'

function GameSettings() {
    return (
        <>
        <Tabs/></>
    )
}

export default GameSettings

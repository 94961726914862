import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";


export const getGameSettingsParameters = async (user) => {

  const docRef = doc(db, "gameparameters", "2L7MNCfO6Ezx1J8xCA9E");
  const docSnap = await getDoc(docRef);

  return docSnap.data()

  // const url ='https://api.ozmonunmaceradunyasi.com/api/process/read';
  //   const headers = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ` + user.accessToken,
  //       },
  //     };
  //     const data = {
  //           "source":"firebase",
  //           "collectionName":"gameparameters",
  //           "documentName":"2L7MNCfO6Ezx1J8xCA9E"
  //     }
  //   const result = await axios.post(url,data,headers);
  //   return result.data;
}

export const updateGameSettingsParameters = async (user,gameParameters) => {
  const url ='https://api.ozmonunmaceradunyasi.com/api/process/create';
    const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + user.accessToken,
        },
      };
      const data = {
        "source": "firebase",
        "collectionName": "gameparameters",
        "documentName": "2L7MNCfO6Ezx1J8xCA9E",
        "data": {
            "gameparameters": gameParameters
            }
        }
    const result = await axios.post(url,data,headers);
    return result.data;
}


import { doc, getDoc } from 'firebase/firestore';
import axios from '../api/axios';
import { db } from '../utils/firebase';

export const addManager = async (newManager, user) => {
  const url = '/api/admin/register';
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + user.accessToken,
    },
  };

  const data = {
    userTypeId: newManager.userTypeId,
    email: newManager.email,
    password: newManager.password,
  };

  const manager = await axios.post(url, data, headers);
  return manager;
};

export const getManagers = async (user) => {
  const url = '/api/process/read/all';
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + user.accessToken,
    },
  };
  const data = {
    source: 'firebase',
    collectionName: 'users',
  };

  const managers = await axios.post(url, data, headers);
  return managers.data;
};
export const getEmailByManagers = async (user) => {
  const url = '/api/parent/get/users?page=1';
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + user.accessToken,
    },
  };
 
  const emails = await axios.post(url, {}, headers);
  return emails.data;
}

export const deleteManager = async (documentName, user) => {
  const url = '/api/process/delete';
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + user.accessToken,
    },
  };
  const data = {
    source: 'firebase',
    collectionName: 'users',
    documentName,
  };
  const result = await axios.post(url, data, headers);
  return result.data;
};

export const changeGameParameters = async (
  user,
  documentName = 'WjHdzm9wk0uTdDcHUv1A',
  changeData
) => {

  const docRef = doc(db, "GameStatistics", documentName);
  const docSnap = await getDoc(docRef);

  return {data: docSnap.data()}

  // const url = '/api/process/create';
  // const headers = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ` + user.accessToken,
  //   },
  // };
  // const data = {
  //   source: 'firebase',
  //   collectionName: 'GameStatistics',
  //   documentName,
  //   data: changeData,
  // };
  // const result = await axios.post(url, data, headers);
  // return result.data;
};

export const getGameParameters = async (user) => {

  const docRef = doc(db, "GameStatistics", "WjHdzm9wk0uTdDcHUv1A");
  const docSnap = await getDoc(docRef);

  return {data: docSnap.data()}

  // const url = '/api/process/read';
  // const headers = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ` + user.accessToken,
  //   },
  // };
  // const data = {
  //   source: 'firebase',
  //   collectionName: 'GameStatistics',
  //   documentName:"WjHdzm9wk0uTdDcHUv1A",
  // };
  // const result = await axios.post(url, data, headers);
  // return result.data;
  
}

export const changePassword = async (user,email,password) => {
  const url = '/api/admin/change/password';
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + user.accessToken,
    },
  };
  const data = {
    email,
    password
  }
  const result = await axios.post(url, data, headers);
  return result.data;

}

export const changeRole = async (user,documentName,role)=>{
  const url = '/api/process/update';
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + user.accessToken,
    },
  };
  const data = {
    collectionName:"users",
    documentName,
  source:"firebase",
  data:[{
    field:"userTypeId",
    value:role
  }]
  }
  console.log(data)
  const result = await axios.post(url, data, headers);
  return result.data;
}
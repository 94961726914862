import {
  MagnifyingGlassIcon,BarsArrowUpIcon
} from '@heroicons/react/20/solid'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {Link} from "react-router-dom";
import DatePicker from "./DatePicker";
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
  export default function Table({title,placeholder}) {
  const {players} = useSelector(state => state.players);
  const [search,setSearch]= useState('');
  const [date,setDate]=useState(new Date());
  //   return true;
  // }
  // return false;
  const filteredPlayers = players?.filter(player => Object.keys(player.data).length !== 0)
  .filter(user => Object.values(user.data).some(value => value.toString().toLowerCase().includes(search.toLowerCase())));

  const downloadExcel = () => {
    const data = filteredPlayers?.map(player => ({
      Name: player.data.name,
      Surname: player.data.surname,
      Email: player.data.email,
      Username: player.data.username,
      Money: player.data.money,
      Happiness: player.data.happiness
    }));

    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Players');
    const excelBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });

    const currentDate = new Date();
    const fileName = `Players_${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}.xlsx`;
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(file, fileName);
  };
  // const sortedList = (sortName,orderBy) => filteredPlayers.sort((a,b) => player.data[sortName])
    return (
      <div className="px-4 sm:px-6 lg:px-8">
       <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h2 className="text-3xl font-semibold text-black mb-2">{title}</h2>
          {/* <DatePicker value={date} onChange={setDate}/> */}

          <form className='flex w-full md:ml-0 mt-5' >
          <label htmlFor='search-field' className='sr-only'>
            Ara
          </label>

          <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
            <div
              className='pointer-events-none absolute inset-y-0 left-0 flex items-center'
              aria-hidden='true'
            >
              <MagnifyingGlassIcon className='h-5 w-5 ml-2' aria-hidden='true' />
            </div>
            <div className="flex justify-between items-center">
            <input
              id='search-field'
              name='search-field'
              onChange={(e) => {setSearch(e.target.value)}}
              className='block h-full w-4/5 border-transparent py-2 pl-8 pr-3 focus:border focus:border-black text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm'
              placeholder={placeholder}
              type='search'
            />
            <span onClick={() => {downloadExcel()}} className="text-sm text-white bg-black rounded cursor-pointer hover:text-white px-2 py-1">Excel indir</span>
            </div>
           
            
          </div>
        </form>
        </div>
        
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
      
        </div>
      </div>

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr className="hover:bg-slate-300">
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Avatar
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Ad Soyad
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Email
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Nickname
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <div className="flex justify-center items-center gap-x-4">
                      <span> Para</span>
                      <span><BarsArrowUpIcon/></span>
                        </div>
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Mutluluk
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        İşlemler
                      </th>
                     
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white ">
                    {(search!=='' ? filteredPlayers : players)?.map((player,index) => (
                      <tr key={player.data.documentName} className={`hover:bg-indigo-300 w-full ${index%2===0 ? "bg-slate-200":null}`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                         <img src={player.avatarImage ? player.avatarImage : ""} alt="" width={"32"}/>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{player.data.name ? player.data.name + " " + player.data.surname : ""}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{player.data.email ? player.data.email : ""}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{player.data.username ? player.data.username : ""}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{player.data.money ? player.data.money : ""}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{player.data.happiness ? player.data.happiness : ""}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm  text-indigo-600 hover:text-indigo-900 cursor-pointer"><Link to={`/player/${player.documentName}`}>Oyuncu Detay</Link></td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
import React from 'react';
import EditManagerForm from './EditManagerForm';
import ManagerModal from '../Modal';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteManager } from '../../services/ManagerService';
function Manager({ person }) {
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const handleDelete = () => {
    const result = window.confirm('Silmek istediğinizden emin misiniz ?');
    if (result === true) {
      deleteManager(person.documentName, user)
        .then((result) => {
          console.log(result);
        })
        .catch((err) => console.log(err));
    } else {
      console.log('silinmedi.');
    }
  };
  function userType(type){
    if(type==="rVTSEqPlPqgh5vADeqhc"){
      return "Manager"
    }
    if(type==="sQc4rDUPAHzqwiph4SPx"){
      return "Marketing Reporter"
    }
    
    return ""
  }
  return (
    <>
  
      <td className='whitespace-nowrap py-4 px-3 text-sm text-gray-500'>
        {person.email}
      </td>
      <td className='whitespace-nowrap py-4 px-3 text-sm text-gray-500'>{userType(person.userTypeId)}</td>
      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
        <button
          onClick={() => {
            setOpen(!open);
          }}
          className='text-indigo-600 hover:text-indigo-900'
        >
          Düzenle<span className='sr-only'>, {person.name}</span>
        </button>
        <ManagerModal open={open} setOpen={setOpen}>
          <EditManagerForm person={person} setOpen={setOpen} open={open} />
        </ManagerModal>
      </td>
      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
        <button
          disabled={user.uid == person.documentName}
          onClick={handleDelete}
          className='text-red-600 hover:text-red-900'
        >
          Sil<span className='sr-only'>, {person.documentName}</span>
        </button>
      </td>
    </>
  );
}

export default Manager;

import React from 'react';
import { addManager } from '../../services/ManagerService';
import { useSelector } from 'react-redux';
import { useState } from 'react';
function AddManagerForm({ open, setOpen }) {
  const { user } = useSelector((state) => state.auth);

  const [userTypeId, setUserTypeId] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addManager({userTypeId,password,email},user).then(result => {
      if(result.status){
        // başarılı result.data documentname veriyor
      }else{
        //başarısız
      };
    }).catch(err => console.log(err))
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className='space-y-8 divide-y divide-gray-200'
      >
        <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
          <div className='space-y-6 sm:space-y-5'>
            <div>
              <h3 className='text-base text-center font-bold leading-6 text-gray-900'>
                Yönetici Ekle
              </h3>
            </div>
          </div>

          <div className='space-y-6 pt-3 sm:space-y-5 sm:pt-4'>
            <div className='space-y-6 sm:space-y-5'>
              {/* <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5'>
                <label
                  htmlFor='first-name'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  Ad
                </label>
                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <input
                    type='text'
                    name='first-name'
                    id='first-name'
                    autoComplete='given-name'
                    className='block w-full px-2 max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  />
                </div>
              </div> */}

              {/* <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label
                  htmlFor='last-name'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  Soyad
                </label>
                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <input
                    type='text'
                    name='last-name'
                    id='last-name'
                    autoComplete='family-name'
                    className='block w-full px-2  max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  />
                </div>
              </div> */}
                  <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  Email
                </label>
                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    autoComplete='email'
                    className='block w-full px-2  max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  />
                </div>
              </div>
              <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  Şifre
                </label>
                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <input
                    type='password'
                    name='password'
                    id='password'
                    value={password}
                    onChange={(e)=> {setPassword(e.target.value)}}
                    autoComplete='password-name'
                    className='block w-full px-2 max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  />
                </div>
              </div>

          
            </div>
          </div>
          <div className='pt-6 sm:pt-5'>
            <div role='group' aria-labelledby='label-email'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4'>
                <div>
                  <div
                    className='text-sm font-semibold leading-6 text-gray-900'
                    id='add-manager'
                  >
                    Yönetici Rolü
                  </div>
                </div>
                <div className='mt-4 sm:col-span-2 sm:mt-0'>
                  <div className='max-w-lg space-y-4'>
                    <div className='relative flex items-start'>
                      <div className='flex h-6 items-center'>
                        <input
                          id='manager'
                          name='role'
                          type='radio'
                          value='rVTSEqPlPqgh5vADeqhc'
                          className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                          onChange={(e) => {setUserTypeId(e.target.value)}}
                        />
                      </div>
                      <div className='ml-3 text-sm leading-6'>
                        <label
                          htmlFor='manager'
                          className='font-medium text-gray-900'
                        >
                          Yönetici
                        </label>
                      </div>
                    </div>
                
                    <div className='relative flex items-start'>
                      <div className='flex h-6 items-center'>
                        <input
                          id='marketing-reporter'
                          name='role'
                          type='radio'
                          value='sQc4rDUPAHzqwiph4SPx'
                          onChange={(e) => {setUserTypeId(e.target.value)}}
                          className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                        />
                      </div>
                      <div className='ml-3 text-sm leading-6'>
                        <label
                          htmlFor='marketing-reporter'
                          className='font-medium text-gray-900'
                        >
                                Marketing Raportör
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-5 sm:mt-6'>
          <button
            type='submit'
            className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            onClick={() => setOpen(false)}
          >
            Kaydet
          </button>
        </div>
      </form>
    </>
  );
}

export default AddManagerForm;

import React, { useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale, // x axis
    LinearScale, // y axis
    PointElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";

ChartJS.register(LineElement,CategoryScale,LinearScale,PointElement,Legend,Title,
    Tooltip,)
const LineChart = ({data, options}) => {
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const chart = chartRef.current.chartInstance;
//     chart.update();
//   }, [data]);

  return (
      <Line  data={data} options={options} />
  );
};

export default LineChart;

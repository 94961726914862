import React, { useState, useEffect } from 'react';
import { getGameSettingsParameters,updateGameSettingsParameters } from '../services/GameSettingsService';
import { useSelector } from 'react-redux';
import toast from "react-hot-toast"
const Tabs = ({ color }) => {
  const [openTab, setOpenTab] = React.useState(2);
  const [gameParameters, setGameParameters] = useState([]);
  const [currentAction, setCurrentAction] = useState("");
  const [currentParams, setCurrentParams] = useState();
  const { user } = useSelector((state) => state.auth);

  const fetchGameSettingsParameters = async () => {
    const result = await getGameSettingsParameters(user);
    const gameParameters = result.data.gameparameters;
    setGameParameters(gameParameters);
    setCurrentAction(gameParameters[0].action);
    setCurrentParams(gameParameters[0])
  };
  const handleActionChange = (action) => {
    setCurrentAction(action);
    const selectedParams = gameParameters.find((params) => params.action === action);
    setCurrentParams(selectedParams);
  };
  const handleSubmitParameters = (e) => {
    e.preventDefault();

    const updatedGameParameters = gameParameters.map((params) =>
      params.action === currentAction ? currentParams : params
    );
 updateGameSettingsParameters(user,updatedGameParameters).then(result => {
if(result.status){
  toast.success("Veriler başarıyla değiştirildi!")
}
setGameParameters(updatedGameParameters);
 }).catch(err => toast.error("Güncelleme işlemi başarısız!"))
    
  };
  useEffect(() => {
    fetchGameSettingsParameters();
  }, []);
  return (
    <>
      <h2 className='text-3xl text-center'> Oyun Ayarları</h2>
      <div className='flex flex-wrap'>
        <div className='w-full'>
          <ul
            className='flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row'
            role='tablist'
          >
            {/* <li className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
              <a
                className={
                  'text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                  (openTab === 1
                    ? ' text-white bg-black'
                    : 'text-black bg-white')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle='tab'
                href='#link1'
                role='tablist'
              >
                Asset Düzenle ( Yapım Aşamasında)
              </a>
            </li> */}
            <li className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
              <a
                className={
                  'text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                  (openTab === 2
                    ? ' text-white bg-black'
                    : 'text-black bg-white')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle='tab'
                href='#link2'
                role='tablist'
              >
                Oyun Parametreleri
              </a>
            </li>
          </ul>
          <div className='relative flex flex-col min-w-0 break-words bg-slate-200 w-full mb-6 shadow-lg rounded'>
            <div className='px-4 py-5 flex-auto'>
              <div className='tab-content tab-space'>
              <div className={openTab === 2 ? 'block' : 'hidden'} id='link2'>
                  <form
                    onSubmit={handleSubmitParameters}
                    className='space-y-8 divide-y divide-gray-200'
                  >
                    <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
                      <div className='space-y-6 sm:space-y-5'>
                        <div className='space-y-6 sm:space-y-5'>
                          <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                            <label
                              htmlFor='action'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Aksiyon
                            </label>
                            <div className='mt-1 sm:col-span-2 sm:mt-0'>
                              <select
                                id='action'
                                name='action'
                                className='block w-full max-w-lg rounded-md border-gray-300 shadow-sm h-8 focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
                                value={currentAction}
                                onChange={(e) =>
                                  handleActionChange(e.target.value)
                                }
                              >
                                {gameParameters.map((params) => (
                                  <option
                                    key={params.action}
                                    value={params.action}
                                  >
                                    {params.action}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                            <div className='sm:col-span-3'>
                              <label
                                htmlFor='moneytopay'
                                className='block text-sm font-medium text-gray-700'
                              >
                                Harcanacak Para
                              </label>
                              <div className='mt-1'>
                                <input
                                  type='text'
                                  name='moneytopay'
                                  id='moneytopay'
                                  autoComplete='given-name'
                                  className='block w-full rounded-md border-2 border-orange-500 h-8 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                  value={currentParams?.moneytopay}
                                  onChange={(e) =>
                                    setCurrentParams({
                                      ...currentParams,
                                      moneytopay: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className='sm:col-span-3'>
                              <label
                                htmlFor='moneytogain'
                                className='block text-sm font-medium text-gray-700'
                              >
                                Kazanılacak Para
                              </label>
                              <div className='mt-1'>
                                <input
                                  type='text'
                                  name='moneytogain'
                                  id='moneytogain'
                                  className='block w-full rounded-md border-2 border-orange-500 h-8 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                  value={currentParams?.moneytogain}
                                  onChange={(e) =>
                                    setCurrentParams({
                                      ...currentParams,
                                      moneytogain: e.target.value,
                                    })
                                  }
                              />
                              </div>
                            </div>
                          </div>
                          <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                            <div className='sm:col-span-3'>
                              <label
                                htmlFor='happinesstopay'
                                className='block text-sm font-medium text-gray-700'
                              >
                                Harcanacak Mutluluk
                              </label>
                              <div className='mt-1'>
                                <input
                                  type='text'
                                  name='happinesstopay'
                                  id='happinesstopay'
                                  className='block w-full rounded-md border-2 border-green-500 h-8 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                  value={currentParams?.happinesstopay}
                                  onChange={(e) =>
                                    setCurrentParams({
                                      ...currentParams,
                                      happinesstopay: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className='sm:col-span-3'>
                              <label
                                htmlFor='happinesstogain'
                                className='block text-sm font-medium text-gray-700'
                              >
                                Kazanılacak Mutluluk
                              </label>
                              <div className='mt-1'>
                                <input
                                  type='text'
                                  name='happinesstogain'
                                  id='happinesstogain'
                                  className='block w-full rounded-md border-2 border-green-500 h-8 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                  value={currentParams?.happinesstogain}
                                  onChange={(e) =>
                                    setCurrentParams({
                                      ...currentParams,
                                      happinesstogain: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='pt-5'>
                      <div className='flex  justify-center'>
                        <button
                          type='submit'
                          className='ml-3  inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        >
                          Güncelle
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* 1. tab */}
                {/* <div className={openTab === 1 ? 'block' : 'hidden'} id='link1'>
                  <form className='space-y-8 divide-y divide-gray-200'>
                    <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
                      <div className='space-y-6 sm:space-y-5'>
                        <div className='space-y-6 sm:space-y-5'>
                          <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                            <label
                              htmlFor='country'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Bölüm Seçiniz
                            </label>
                            <div className='mt-1 sm:col-span-2 sm:mt-0'>
                              <select
                                id='country'
                                name='country'
                                autoComplete='country-name'
                                className='block w-full max-w-lg rounded-md border-gray-300 shadow-sm h-8 focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
                              >
                                <option>1. Bölüm</option>
                                <option>2. Bölüm</option>
                                <option>3. Bölüm</option>
                              </select>
                            </div>
                          </div>
                          <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-400 sm:pt-5'>
                            <label
                              htmlFor='country'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Nesne Seçiniz
                            </label>
                            <div className='mt-1 sm:col-span-2 sm:mt-0'>
                              <select
                                id='country'
                                name='country'
                                autoComplete='country-name'
                                className='block w-full max-w-lg rounded-md border-gray-300 h-8 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
                              >
                                <option>Bıçak</option>
                                <option>Kılıç</option>
                                <option>Kalkan</option>
                              </select>
                            </div>
                          </div>

                          <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-400 sm:pt-5'>
                            <label
                              htmlFor='cover-photo'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Asset Yükle
                            </label>
                            <div className='mt-1 sm:col-span-2 sm:mt-0'>
                              <div className='flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6'>
                                <div className='space-y-1 text-center'>
                                  <svg
                                    className='mx-auto h-12 w-12 text-gray-400'
                                    stroke='currentColor'
                                    fill='none'
                                    viewBox='0 0 48 48'
                                    aria-hidden='true'
                                  >
                                    <path
                                      d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                                      strokeWidth={2}
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                  </svg>
                                  <div className='flex text-sm text-gray-600'>
                                    <label
                                      htmlFor='file-upload'
                                      className='relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500'
                                    >
                                      <span>Bir Dosya Yükleyiniz.</span>
                                      <input
                                        id='file-upload'
                                        name='file-upload'
                                        type='file'
                                        className='sr-only'
                                      />
                                    </label>
                                    <p className='pl-1'>Sürükle ve bırak</p>
                                  </div>
                                  <p className='text-xs text-gray-500'>
                                    10MB sınır var.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-400 sm:pt-5'>
                            <label
                              htmlFor='first-name'
                              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                            >
                              Dosya linki
                            </label>
                            <div className='mt-1 sm:col-span-2 sm:mt-0'>
                              <input
                                type='text'
                                name='first-name'
                                id='first-name'
                                autoComplete='given-name'
                                className='block w-full max-w-lg rounded-md h-8 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='pt-5'>
                      <div className='flex  justify-center'>
                        <button
                          type='submit'
                          className='ml-3  inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        >
                          Güncelle
                        </button>
                      </div>
                    </div>
                  </form>
                </div> */}
                {/* 2.tab */}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender() {
  return (
    <>
      <Tabs color='green' />
    </>
  );
}

import { collection, getDocs, query, where } from 'firebase/firestore';
import axios from '../api/axios';
import toast from 'react-hot-toast';
import { db } from '../utils/firebase';

export const isAdmin = async (user) => {

  console.log('email', user.email)

  const q = query(collection(db, "users"), where("userEmail", "==", user.email));

  const querySnapshot = await getDocs(q);

  let isAdmin = false;
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    if(doc.data().userType == "admin") {
      isAdmin = true;
    }
  });
  return isAdmin;
};

// export const LoginService = async (user) => {
//   const url = 'api/admin/login';
//   const headers = {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ` + user.accessToken,
//     },
//   };
//   toast.success('Başarılı bir şekilde giriş yapıldı!');
//   const result = await axios.post(url,{},headers);
//   return result.data;
// };

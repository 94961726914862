import axios from "axios";
import { collection, doc, getCountFromServer, getDoc, getFirestore, query } from "firebase/firestore";
import { db } from '../utils/firebase';

export const getTotalPlayer = async (user) => {
  const q = query(collection(db, "players"));
  const count = await getCountFromServer(q);
  const countText = await count.data().count

  console.log("countText", countText)
  // const url = 'https://api.ozmonunmaceradunyasi.com/api/admin/player/totalPlayer';
  // const headers = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ` + user.accessToken,
  //   },
  // };
  // const result = await axios.get(url, headers);
  // console.log('result :>> ', result);
  // return result.data;
  return {count: countText}
}

export const getPopupPressStat = async (user) => {
  const docRef = doc(db, "PopUpData", "9v8XaxjVneVOEoP6kck9");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
}

export const getTimeInGame = async (user) => {
  const url = 'https://api.ozmonunmaceradunyasi.com/api/process/read';
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + user.accessToken,
    },
  };
  const data = {
    "source": "firebase",
    "collectionName": "passedtime",
    "documentName": "2L7MNCfO6Ezx1J8xCA9E"
  }
  const result = await axios.post(url, data, headers);

  return result.data.data;
}

export const getParts = async (user) => {
  // const url = 'https://api.ozmonunmaceradunyasi.com/api/process/read';
  // const headers = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ` + user.accessToken,
  //   },
  // };
  // const data = {
  //   "source": "firebase",
  //   "collectionName": "playersoflevels",
  //   "documentName": "2L7MNCfO6Ezx1J8xCA9E"
  // }
  // const result = await axios.post(url, data, headers);

  const docRef = doc(db, 'playersoflevels', '6CnNzsHb19heO16kKOWIHLr4XYt1');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    console.log('No such document!');
  }
}

export const getGamesDurations = async (user) => {
  // const url = 'https://api.ozmonunmaceradunyasi.com/api/process/read';
  // const headers = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `m6c45GM7Ysh8DpJFG0KE6kVj0HH3`,
  //   },
  // };
  // const data = {
  //   "source": "firebase",
  //   "collectionName": "TimeSpendOfLevels",
  //   "documentName": "WjHdzm9wk0uTdDcHUv1A"
  // }
  // const result = await axios.post(url, data, headers);

  const docRef = doc(db, "miniGameSessions", "WjHdzm9wk0uTdDcHUv1A");
  const docSnap = await getDoc(docRef);

  console.log('data', docSnap.data())

  return docSnap.data();
}

export const getItems = async (user) => {
  const url = 'https://api.ozmonunmaceradunyasi.com/api/takenItems';
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + user.accessToken,
    },
  };
  const data = {
  }
  const result = await axios.post(url, data, headers);

  return result.data;
}
export function convertReadableTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor(totalSeconds / 3600) % 24;
    const minutes = Math.floor(totalSeconds / 60) % 60;
    const seconds = totalSeconds % 60;
    let result = "";
    if (days) result += `${days} gün `;
    if (hours) result += `${hours} saat `;
    if (minutes) result += `${minutes} dakika `;
    if (seconds) result += `${seconds} saniye`;
    return result;  
  }
  
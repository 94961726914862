import { useState, useEffect, Fragment } from "react";
import { addNotification, getNotifications, removeNotification } from "../services/NotificationService"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import { TrashIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import DatePicker from 'react-datepicker';
import { tr } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment/moment";
const MySwal = withReactContent(Swal);

export default function Notifications() {
    const { user } = useSelector((state) => state.auth);
    const [notifications, setNotifications] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedPublishTime, setSelectedPublishTime] = useState(new Date());
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationBody, setNotificationBody] = useState('');

    const confirmDeleteNotification = (documentName) => {

        MySwal.fire({
            cancelButtonColor: "#6e7881",
            confirmButtonColor: "#211572",
            title: "",
            type: "warning",
            text: "Bildirimi silmek istediğinize emin misiniz?",
            showCloseButton: true,
            showConfirmButton: true,
            allowOutsideClick: true,
            confirmButtonText: "Evet",
            showCancelButton: true,
            cancelButtonText: "Hayır",
            allowEscapeKey: true,
            onClose: function () { },
        }).then((result) => {
            if (result.isConfirmed) {
                deleteNotification(documentName)
            }
        });
    }

    const timestampToDateTime = (timestamp) => {
        return moment(timestamp).format('DD/MM/YYYY HH:mm:ss')
    }

    const saveNotification = async () => {
        if (notificationBody.length > 0) {
            const result = await addNotification(notificationTitle, notificationBody, selectedPublishTime.getTime());
            setShowModal(false)
            fetchNotifications()
        }
        else {
            MySwal.fire({ title: "", text: "Lütfen bildirim metni giriniz.", icon: "error", showConfirmButton: false, showCloseButton: true }).then((result) => {

            })
        }
    }

    const deleteNotification = async (documentName) => {
        const result = await removeNotification(documentName);
        setShowModal(false)
        fetchNotifications()
    }

    const fetchNotifications = async () => {
        const notifications = await getNotifications();
        notifications?.data?.map((d) => d.date = timestampToDateTime(d.data.publishTime))
        console.log("notifications", notifications.data)
        setNotifications(notifications?.data)
    }
    useEffect(() => {
        fetchNotifications()
    }, [])

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setNotifications({ ...Notifications, [name]: parseFloat(value) });

    // }
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const documentName="WjHdzm9wk0uTdDcHUv1A"
    //   const result=  await changeNotifications(user,documentName,Notifications);
    // if(result.status){
    //     toast.success("Oyun Parametreleri Başarıyla güncellendi.")
    // }else{
    //     toast.success("Oyun Parametreleri Güncellenemedi. Tekrar deneyiniz!")
    // }
    // }

    return (
        <div className="space-y-8">
            <div className="flex justify-between">
                <div>
                    <img src="/images/notification_preview.png" className="mb-10 w-72"></img>
                </div>
                <div className='px-4 py-2  sm:px-6 lg:px-8 sm:flex-auto'>
                    <h1 className='text-lg font-bold leading-6 text-gray-900'>
                        Bildirim Yönetimi
                    </h1>
                    <br />
                    <span className="font-medium text-gray-600">Aşağıda planlanan bildirimleri görebilir, yeni bildirim planlayabilirsiniz.<br/> Başlık ve metin tüm kullanıcıların cihazlarına gönderilecektir.</span>
                </div>

            </div>

            <div className="flex justify-end">
                <button onClick={() => { setNotificationTitle(''); setNotificationBody(''); setShowModal(true) }} className="rounded-md bg-cyan-800 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm">Yeni Bildirim Oluştur</button>
            </div>

            <div className="">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr className="hover:bg-slate-300">
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Başlık
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Metin
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Tarih
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Durum
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                İşlemler
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white ">
                        {notifications && notifications.length > 0 && notifications.map(n => (
                            <tr>
                                <td style={{ width: 500, padding: 10 }}>{n.data.title}</td>
                                <td style={{ width: 500, padding: 10 }}>{n.data.body}</td>
                                <td>{n.date}</td>
                                <td>{n.data.status === "waiting" ? "Bekliyor" : "Gönderildi"}</td>
                                <td><button onClick={() => confirmDeleteNotification(n.documentName)} className="my-2 rounded-md bg-red-800 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm">Sil</button></td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            {/* <form onSubmit={handleSubmit}>
                <div className='space-y-8 md:space-y-12 lg:px-4'>
                    <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 mx-4 md:grid-cols-3'>
                        <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2'>
                            <div className='sm:col-span-2 sm:col-start-1'>
                                <label
                                    htmlFor='aiSpeedMultiplier'
                                    className='block text-sm font-bold leading-6 text-gray-900'
                                >
                                    Yarış Zorluğu
                                </label>
                                <div className='mt-2'>
                                    <input
                                        type='number'
                                        name='aiSpeedMultiplier'
                                        id='aiSpeedMultiplier'
                                        onChange={handleChange}
                                        value={Notifications.aiSpeedMultiplier}
                                        autoComplete='address-level2'
                                        className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    />
                                </div>
                            </div>

                            <div className='sm:col-span-2'>
                                <label
                                    htmlFor='basketCount'
                                    className='block text-sm font-bold leading-6 text-gray-900'
                                >
                                    Basketbol Atış Sayısı
                                </label>
                                <div className='mt-2'>
                                    <input
                                        type='number'
                                        name='basketCount'
                                        id='basketCount'
                                        autoComplete='basketCount'
                                        onChange={handleChange}
                                        value={Notifications.basketCount}
                                        className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    />
                                </div>
                            </div>

                            <div className='sm:col-span-2'>
                                <label
                                    htmlFor='lapCount'
                                    className='block text-sm font-bold leading-6 text-gray-900'
                                >
                                    Yarış Tur Sayısı
                                </label>
                                <div className='mt-2'>
                                    <input
                                        type='number'
                                        name='lapCount'
                                        id='lapCount'
                                        onChange={handleChange}
                                        value={Notifications.lapCount}
                                        autoComplete='lapCount'
                                        className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    />
                                </div>
                            </div>
                            <div className='sm:col-span-2 sm:col-start-1'>
                                <label
                                    htmlFor='platformSpawnRate'
                                    className='block text-sm font-bold leading-6 text-gray-900'
                                >
                                    Platform Zorluğu
                                </label>
                                <div className='mt-2'>
                                    <input
                                        type='number'
                                        name='platformSpawnRate'
                                        id='platformSpawnRate'
                                        onChange={handleChange}
                                        value={Notifications.platformSpawnRate}
                                        autoComplete='platformSpawnRate'
                                        className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    />
                                </div>
                            </div>

                            <div className='sm:col-span-2'>
                                <label
                                    htmlFor='platformSpeed'
                                    className='block text-sm font-bold leading-6 text-gray-900'
                                >
                                    Platform Hızı
                                </label>
                                <div className='mt-2'>
                                    <input
                                        type='number'
                                        name='platformSpeed'
                                        id='platformSpeed'
                                        onChange={handleChange}
                                        value={Notifications.platformSpeed}
                                        autoComplete='platformSpeed'
                                        className='block w-full rounded-md px-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    />
                                </div>
                            </div>

                            <div className='sm:col-span-2'>
                                <label
                                    htmlFor='speedMultiplier'
                                    className='block text-sm font-bold leading-6 text-gray-900'
                                >
                                    Yarışçı hızı
                                </label>
                                <div className='mt-2'>
                                    <input
                                        type='number'
                                        name='speedMultiplier'
                                        id='speedMultiplier'
                                        onChange={handleChange}
                                        value={Notifications.speedMultiplier}
                                        autoComplete='speedMultiplier'
                                        className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    />
                                </div>
                            </div>
                            <div className='sm:col-span-2 sm:col-start-1'>
                                <label
                                    htmlFor='timeMultiplier'
                                    className='block text-sm font-bold leading-6 text-gray-900'
                                >
                                    Bekleme Zaman Çarpanı
                                </label>
                                <div className='mt-2'>
                                    <input
                                        type='number'
                                        name='timeMultiplier'
                                        id='timeMultiplier'
                                        onChange={handleChange}
                                        value={Notifications.timeMultiplier}
                                        autoComplete='timeMultiplier'
                                        className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    />
                                </div>
                            </div>

                            <div className='sm:col-span-2'>
                                <label
                                    htmlFor='valueMultipliev'
                                    className='block text-sm font-bold leading-6 text-gray-900'
                                >
                                    Kazanım Çarpanı
                                </label>
                                <div className='mt-2'>
                                    <input
                                        type='number'
                                        name='valueMultipliev'
                                        id='valueMultipliev'
                                        autoComplete='valueMultipliev'
                                        onChange={handleChange}
                                        value={Notifications.valueMultipliev}
                                        className='block w-full rounded-md px-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    />
                                </div>
                            </div>


                            <div className=' col-span-full mt-6 flex items-center justify-end  gap-x-6'>
                                <button
                                    type='submit'
                                    className='rounded-md bg-indigo-600 px-8 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                >
                                    Güncelle
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form> */}

            <Transition.Root show={showModal} as={Fragment}>
                <Dialog className="relative z-10" onClose={setShowModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 h-[600px]">
                                    <div>

                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 mb-10">
                                                Yeni Bildirim Planla
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <form className="max-w-sm mx-auto">
                                                    <div className="mb-10">
                                                        <label
                                                            htmlFor="email"
                                                            className="block mb-2 text-sm font-medium text-gray-900"
                                                        >
                                                            Bildirim Başlığı (opsiyonel)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="text"
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                                            placeholder=""
                                                            required=""
                                                            defaultValue={notificationTitle}
                                                            onChange={(e) => setNotificationTitle(e.target.value)}>
                                                        </input>
                                                    </div>

                                                    <div className="mb-10">
                                                        <label
                                                            htmlFor="email"
                                                            className="block mb-2 text-sm font-medium text-gray-90"
                                                        >
                                                            Bildirim Metni
                                                        </label>
                                                        <textarea
                                                            type="text"
                                                            id="text"
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                                            placeholder=""
                                                            required=""
                                                            onChange={(e) => setNotificationBody(e.target.value)}
                                                        >{notificationBody}</textarea>
                                                    </div>

                                                    <div className="mb-10">
                                                        <label
                                                            htmlFor="password"
                                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                                        >
                                                            Tarih - Saat
                                                        </label>

                                                        <div className="border border-gray-300">
                                                            <DatePicker
                                                                selected={selectedPublishTime}
                                                                onChange={(date) => { console.log("date", date); setSelectedPublishTime(date) }}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="Zaman"
                                                                dateFormat="dd/MM/yyyy HH:mm"
                                                                className="p-2 w-full"
                                                                locale={tr}
                                                            /></div>

                                                    </div>
                                                    <a
                                                        type="submit"
                                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                                                        onClick={() => saveNotification()}
                                                    >
                                                        Ekle
                                                    </a>
                                                </form>

                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>

    );
}

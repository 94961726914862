import React, { useEffect, useState } from 'react';
import { getGamesDurations, getItems, getParts, getTimeInGame } from '../services/StatService';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js"
import DatePicker from "../components/DatePicker"
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { db } from '../utils/firebase';

ChartJS.register(BarElement)
function ReportsLive() {
  const { user } = useSelector((state) => state.auth);
  const [reports, setReports] = useState([]);
  const [valueStart, setValueStart] = useState(new Date())
  const [valueEnd, setValueEnd] = useState(new Date())

  const [reports2, setReports2] = useState(false);
  const [reports3, setReports3] = useState(false);

  const getReportsOfParts = async () => {
    // const result = await getParts(user);
    // setReports(result.data);
    // const result2 = await getGamesDurations(user);
    // setReports2(result2.data);
    const result3 = await getItems(user);
    setReports3(result3.data);
  };

  const getReportsFirebase = async () => {
    const docRef = doc(db, "miniGameSessions", "dkSVmfmGHfxJMlk3KsPL");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("data", docSnap.data())
      setReports2(docSnap.data());
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  useEffect(() => {
    getReportsFirebase();
    getReportsOfParts();
  }, []);


  const renderChart2 = () => {
    console.log("reports2", reports2)
    const levelNames = reports2.miniGameNames?.map((level, index) => level);
    const playerCounts = reports2.miniGameDurations?.map((time, index) => (time));

    const data = {
      labels: levelNames,
      datasets: [
        {
          label: 'Hangi Oyunda Ne Kadar Süre Geçirildi?',
          data: playerCounts,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    };

    return <Bar data={data} options={config} />;
  };

  const renderChart3 = () => {

    console.log("reports2", reports2)
    const levelNames = reports2.miniGameNames?.map((level, index) => level);
    const playerCounts = reports2.miniGameInteractions?.map((time, index) => (time));

    const data = {
      labels: levelNames,
      datasets: [
        {
          label: 'Hangi Oyun Kaç Kez Oynanıldı?',
          data: playerCounts,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    };

    return <Bar data={data} options={config} />;
  };

  return (
    <>
      <div className=" mb-2">
        <h2 className="text-3xl font-semibold text-black mb-10">Raporlar</h2>
        {/* <div className='grid grid-cols-2 gap-4 place-content-center w-80'>
        <span><DatePicker  onChange={setValueStart} value={valueStart}/></span>
        <span><DatePicker  onChange={setValueEnd} value={valueEnd}/></span>
      </div>
      
      {reports?.levelname?.length > 0 ? renderChart() : null} */}
      </div>

      <div className="px-2">
        <div className='grid grid-cols-2 gap-4 place-content-center w-80'>
        </div>

        <p className='mb-5'>Alttaki grafik, saniye bazında geçirilen süreleri göstermektedir.</p>
        <p></p>
        <div style={{ width: "45%" }}>
          {reports2?.miniGameNames?.length > 0 ? renderChart2() : null}
        </div>

        <div style={{ width: "45%" }} className='mt-10'>
          {reports2?.miniGameNames?.length > 0 ? renderChart3() : null}
        </div>
      </div>
    </>
  );
}

export default ReportsLive;

import {configureStore} from "@reduxjs/toolkit";

import players from "./players";
import auth from "./auth";

const store = configureStore({
    reducer: {
        players,
        auth
    }
})


export default store;
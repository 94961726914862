import {useState,useEffect} from "react";
import {changeGameParameters,getGameParameters} from "../services/ManagerService"
import {useSelector} from "react-redux"
import {toast} from "react-hot-toast"
export default function GameParameters() {
    const { user } = useSelector((state) => state.auth);
const [gameParameters,setGameParameters] = useState({
    aiSpeedMultiplier:0,
    basketCount:0,
    lapCount:0,
    platformSpawnRate:0,
    platformSpeed:0,
    speedMultiplier:0,
    timeMultiplier:0,
    valueMultipliev:0,
    time:""
})

useEffect(() => {
    getGameParameters(user).then(result => {

      setGameParameters(result.data)
        toast.success("Oyun Parametreleri başarıyla getirildi!");
    }).catch(err => {
        toast.error("Oyun Parametreleri getirilemedi!")
    }) 
},[])
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setGameParameters({ ...gameParameters, [name]: parseFloat(value) });
    
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const documentName="WjHdzm9wk0uTdDcHUv1A"
      const result=  await changeGameParameters(user,documentName,gameParameters);
    if(result.status){
        toast.success("Oyun Parametreleri Başarıyla güncellendi.")
    }else{
        toast.success("Oyun Parametreleri Güncellenemedi. Tekrar deneyiniz!")
    }
    }

  return (
    <div className="space-y-8">
         <div className='px-4 py-2  sm:px-6 lg:px-8 sm:flex-auto'>
          <h1 className='text-lg font-bold leading-6 text-gray-900'>
            Oyun Parametreleri
          </h1>
          <br/>
          <span className="font-medium text-gray-600">Son Güncellenme Tarihi : {gameParameters.time} GPT-0</span>
        </div>
        <form onSubmit={handleSubmit}>
      <div className='space-y-8 md:space-y-12 lg:px-4'>
        <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 mx-4 md:grid-cols-3'>
          <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2'>
            <div className='sm:col-span-2 sm:col-start-1'>
              <label
                htmlFor='aiSpeedMultiplier'
                className='block text-sm font-bold leading-6 text-gray-900'
              >
                Yarış Zorluğu
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  name='aiSpeedMultiplier'
                  id='aiSpeedMultiplier'
                  onChange={handleChange}
                  value={gameParameters.aiSpeedMultiplier}
                  autoComplete='address-level2'
                  className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='basketCount'
                className='block text-sm font-bold leading-6 text-gray-900'
              >
                Basketbol Atış Sayısı
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  name='basketCount'
                  id='basketCount'
                  autoComplete='basketCount'
                  onChange={handleChange}
                  value={gameParameters.basketCount}
                  className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='lapCount'
                className='block text-sm font-bold leading-6 text-gray-900'
              >
            Yarış Tur Sayısı
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  name='lapCount'
                  id='lapCount'
                  onChange={handleChange}
                  value={gameParameters.lapCount}
                  autoComplete='lapCount'
                  className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            <div className='sm:col-span-2 sm:col-start-1'>
              <label
                htmlFor='platformSpawnRate'
                className='block text-sm font-bold leading-6 text-gray-900'
              >
                Platform Zorluğu
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  name='platformSpawnRate'
                  id='platformSpawnRate'
                  onChange={handleChange}
                  value={gameParameters.platformSpawnRate}
                  autoComplete='platformSpawnRate'
                  className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='platformSpeed'
                className='block text-sm font-bold leading-6 text-gray-900'
              >
               Platform Hızı
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  name='platformSpeed'
                  id='platformSpeed'
                  onChange={handleChange}
                  value={gameParameters.platformSpeed}
                  autoComplete='platformSpeed'
                  className='block w-full rounded-md px-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='speedMultiplier'
                className='block text-sm font-bold leading-6 text-gray-900'
              >
                Yarışçı hızı
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  name='speedMultiplier'
                  id='speedMultiplier'
                  onChange={handleChange}
                  value={gameParameters.speedMultiplier}
                  autoComplete='speedMultiplier'
                  className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            <div className='sm:col-span-2 sm:col-start-1'>
              <label
                htmlFor='timeMultiplier'
                className='block text-sm font-bold leading-6 text-gray-900'
              >
                Bekleme Zaman Çarpanı
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  name='timeMultiplier'
                  id='timeMultiplier'
                  onChange={handleChange}
                  value={gameParameters.timeMultiplier}
                  autoComplete='timeMultiplier'
                  className='block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label
                htmlFor='valueMultipliev'
                className='block text-sm font-bold leading-6 text-gray-900'
              >
                Kazanım Çarpanı
              </label>
              <div className='mt-2'>
                <input
                  type='number'
                  name='valueMultipliev'
                  id='valueMultipliev'
                  autoComplete='valueMultipliev'
                  onChange={handleChange}
                  value={gameParameters.valueMultipliev}
                  className='block w-full rounded-md px-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

       
            <div className=' col-span-full mt-6 flex items-center justify-end  gap-x-6'>
              <button
                type='submit'
                className='rounded-md bg-indigo-600 px-8 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                Güncelle
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
    
  );
}

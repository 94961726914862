import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import toast from 'react-hot-toast';
import store from '../store';
import { login as loginHandle, logout as logoutHandle } from '../store/auth';
import { getFirestore, query, collection, where, getDocs } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDBVP51xEu_JO8fw0s35oiPKSjsM8XA53M",
  authDomain: "ozmonunmaceradunyasi.firebaseapp.com",
  databaseURL: "https://ozmonunmaceradunyasi-default-rtdb.firebaseio.com",
  projectId: "ozmonunmaceradunyasi",
  storageBucket: "ozmonunmaceradunyasi.appspot.com",
  messagingSenderId: "62160329578",
  appId: "1:62160329578:web:98db6d1a8fc3a4df902313",
  measurementId: "G-51F0HFDB71"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore(app);
export { auth, db, query, collection, where, getDocs };

export const login = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);

    return user;
  } catch (error) {
    toast.error(error.message);
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
    toast.success('Başarılı bir şekilde çıkış yapıldı!');
    return true;
  } catch (error) {
    toast.error(error.message);
  }
};

onAuthStateChanged(auth, async (user) => {
  try {
    if (user) {
      const { accessToken, refreshToken, expirationTime } =
        auth.currentUser.stsTokenManager;
      const { displayName, email, photoURL, emailVerified, uid, providerId } =
        auth.currentUser;
      const userDetail = {
        displayName,
        email,
        photoURL,
        emailVerified,
        accessToken,
        uid,
        providerId,
        refreshToken,
        expirationTime,
      };

      if (expirationTime < Date.now()) {
        //refresh accessToken
        const updatedAccessToken = await user.getIdToken(true);
        store.dispatch(
          loginHandle({ ...userDetail, accessToken: updatedAccessToken })
        );
        toast("Sayfayı yenileyiniz!");
      } else {
        store.dispatch(loginHandle(userDetail));
      }
    } else {
      store.dispatch(logoutHandle());
    }
  } catch (error) {
    toast.error(error.message);
  }
});

import React, { useEffect, useState } from 'react';
import { getGamesDurations, getItems, getParts, getTimeInGame } from '../services/StatService';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js"
import DatePicker from "../components/DatePicker"
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { db } from '../utils/firebase';

ChartJS.register(BarElement)
function Items() {
  const { user } = useSelector((state) => state.auth);
  const [reports, setReports] = useState([]);
  const [valueStart, setValueStart] = useState(new Date())
  const [valueEnd, setValueEnd] = useState(new Date())

  const [reports2, setReports2] = useState(false);
  const [reports3, setReports3] = useState(false);

  const getReportsFirebase = async () => {
    const docRef = doc(db, "shopPurchaseData", "iC7m6LKJnX4RMkRxxkrt");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("data", docSnap.data())
      let data = docSnap.data();
      const result = Object.entries(data).reduce((acc, [key, array]) => {
        if(key != "time") {
            const sum = array.reduce((total, num) => total + num, 0);
            acc[key] = sum;
        }
        return acc;
      }, {});
  
      setReports2(result);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  useEffect(() => {
    getReportsFirebase();
  }, []);

  useEffect(() => {
    console.log("reports2", reports2)
  }, [reports2]);


  const renderChart2 = () => {
    console.log("reports2", reports2)
    const levelNames = Object.keys(reports2);
    const playerCounts = Object.values(reports2);

    const data = {
      labels: levelNames,
      datasets: [
        {
          label: 'Satın Alınan Eşyalar',
          data: playerCounts,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    };

    return <Bar data={data} options={config} />;
  };

  return (
    <>
      <div className=" mb-2">
        <h2 className="text-3xl font-semibold text-black mb-10">Satın Alınan Eşyalar</h2>
        {/* <div className='grid grid-cols-2 gap-4 place-content-center w-80'>
        <span><DatePicker  onChange={setValueStart} value={valueStart}/></span>
        <span><DatePicker  onChange={setValueEnd} value={valueEnd}/></span>
      </div>
      
      {reports?.levelname?.length > 0 ? renderChart() : null} */}
      </div>

      <div className="px-2">


        <p className='mb-5'>Alttaki grafik, satın alınan eşyaları ve sayısını göstermektedir.</p>
        <p></p>
        <div style={{ width: "60%" }}>
          {reports2 ? renderChart2() : null}
        </div>
      </div>
    </>
  );
}

export default Items;

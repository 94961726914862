import { useState } from 'react';
import ManagerModal from '../Modal';
import Manager from './Manager';
import AddManagerForm from './AddManagerForm';
import { useSelector } from 'react-redux';
import { getManagers, getEmailByManagers } from '../../services/ManagerService';
import { useEffect } from 'react';
import Loading from "../Loading"
// ! Redux ekle
export default function ManagerList() {
  const [loading,setLoading]=useState(true);
  const { user } = useSelector((state) => state.auth);
  const [managers, setManagers] = useState([]);
  
  const fetchManagers = async () => {
    try {
      const [rolesResult, emailsResult] = await Promise.all([
        getManagers(user),
        getEmailByManagers(user)
      ]);
      const roles = rolesResult.data;
      const emails = emailsResult.data;
  
      const combineArray = roles.map((role) => {
        const emailObj = emails.find((email) => email.rawId === role.documentName);
        return {
          userTypeId: role.data.userTypeId,
          documentName: role.documentName,
          email: emailObj ? emailObj.email : ''
        };
      });
      
      setManagers(combineArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  

  
  useEffect(() => {
    fetchManagers();
  }, []);
  const [open, setOpen] = useState(false);
  if(loading){
    return <Loading/>
  }
  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-bold leading-6 text-gray-900'>
            Yöneticiler
          </h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            type='button'
            className='block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            onClick={() => {
              setOpen(!open);
            }}
          >
            Yeni Yönetici Ekle
          </button>
          <ManagerModal open={open} setOpen={setOpen}>
            <AddManagerForm open={open} setOpen={setOpen} />
          </ManagerModal>
        </div>
      </div>
      <div className='mt-8 flow-root'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500'
                  >
                    Email
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500'
                  >
                    Rol
                  </th>
                  <th scope='col' className='relative py-3 pl-3 pr-4 sm:pr-0'>
                    <span className='sr-only'>Düzenle</span>
                    <span className='sr-only'>Sil</span>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {managers?.map((person) => (
                  <tr key={person.email}>
                    <Manager person={person} />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

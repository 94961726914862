// import { useState } from 'react';
// import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline';
import SearchBar from '../components/SearchBar';
// import PageHeader from '../components/PageHeader';
import Stats from '../components/Stats';

export default function Home() {
  // const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      {/* <div className='flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none'>
        <SearchBar /> 
      </div> */}
      <main className='flex-1 pb-8'>
        {/* <BreadCrumb /> */}

        {/* <PageHeader /> */}
        <div className='mt-8'>
          <div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8'>
            <Stats />
          </div>
        </div>
      </main>
    </>
  );
}

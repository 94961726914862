import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import {  useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { setPagination } from '../store/players';
export default function Pagination({currentPage}) {
  const {page,limit,totalPlayer} =useSelector(state => state.players.pagination);
  const dispatch = useDispatch();
  const pageCount = Math.ceil(totalPlayer/limit);
  const maxPageDisplay = 10;
  const startPage = Math.max(1, page - Math.floor(maxPageDisplay / 2));
  const endPage = Math.min(pageCount, startPage + maxPageDisplay - 1);
  const startEllipsis = Math.max(1, endPage - maxPageDisplay + 1);
  const endEllipsis = Math.max(1, startPage - 1);
  
  return (
    
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <Link
         to={`/players/${currentPage-1}`}
          className={`inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${page === 1 ? "cursor-not-allowed opacity-50" : null}`}
          onClick={() => page !== 1 && page<pageCount && 0<page && dispatch(setPagination({page:parseInt(page-1),limit,totalPlayer})) }
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          Önceki
        </Link>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {startEllipsis > 1 && (
          <Link
            to={`/players/1`}
            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            1
          </Link>
        )}
        {startEllipsis > 2 && <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>}
        {Array.from(Array(endPage - startPage + 1), (e, i) => {
          return (
            <Link
              to={`/players/${startPage + i}`}
              className={`inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${page === startPage + i ? "border-indigo-500 text-indigo-600" : null}`}
            >
              {startPage + i}
            </Link>
          );
        })}
        {endEllipsis < pageCount - 1 && pageCount!==currentPage && <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>}
{endEllipsis < pageCount && pageCount!==currentPage && (

  <Link
    to={`/players/${pageCount}`}
    className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
  >
    {pageCount}
  </Link>
)}
</div>
<div className="-mt-px flex w-0 flex-1 justify-end">
  <Link
    to={`/players/${currentPage+1}`}
    className={`inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${page === pageCount ? "cursor-not-allowed opacity-50" : null}`}
    onClick={() => page !== pageCount && page<pageCount && 0<page && dispatch(setPagination({page:parseInt(page+1),limit,totalPlayer}))}
  >
    Sonraki
    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
  </Link>
</div>
</nav>
);
}
import { Routes, Route, Navigate } from 'react-router-dom';
import {Toaster} from "react-hot-toast";

// * Pages
import Home from './pages/Home';
import Players from './pages/Players';
import Reports from './pages/Reports';
import GameSettings from './pages/GameSettings';
import UsageStatics from './pages/UsageStatics';

// * Components
import Login from './components/Login';
import PlayerDetail from './components/PlayerDetail';
import DepartmentStatics from './pages/DepartmentStatics';
import DemographicStatics from './pages/DemographicStatics';

// * Layout
import AppLayout from './layout/AppLayout';
import AppLayoutDefault from './layout/AppLayoutDefault';
import RequireAuth from './utils/RequireAuth';
import ManagerList from './components/Manager/ManagerList';
import GameParameters from './components/GameParameters';
import ReportsLive from './pages/ReportsLive';
import Notifications from './components/Notifications';
import Items from './pages/Items';

// * Util
// import AppRoute from "./utils/AppRoute";
function Router() {
  return (
    <>
    <Toaster position='center'/>
      <Routes> 
      
          <Route path='/login' element={<AppLayoutDefault><Login /></AppLayoutDefault>}/>
          {/* Protected Auth */}
          <Route element={<RequireAuth/>}>
            <Route path='/' element={<AppLayout><Home /></AppLayout>}  />
            <Route exact path='/players/:page' element={<AppLayout><Players/></AppLayout>} />
            <Route exact path='/players/' element={<Navigate to="/players/1"/>} />
            <Route path='/reports-live' element={<AppLayout><ReportsLive/></AppLayout>}  />
            <Route path='/reports' element={<AppLayout><Reports/></AppLayout>}  />
            <Route path='/items' element={<AppLayout><Items/></AppLayout>}  />
            <Route path='/game-settings' element={<AppLayout><GameSettings /></AppLayout>} />
            <Route path='/player/:id' element={<AppLayout><PlayerDetail /></AppLayout>}  />
            <Route path='/reports/department' element={<AppLayout><DepartmentStatics /></AppLayout>}  />
            <Route path='/reports/demographic' element={<AppLayout><DemographicStatics /></AppLayout>} />
            <Route path='/reports/usage' element={<AppLayout><UsageStatics /></AppLayout>} />
            <Route path='/managers' element={<AppLayout><ManagerList /></AppLayout>} />
            <Route path='/game-parameters' element={<AppLayout><GameParameters /></AppLayout>} />
            <Route path='/notifications' element={<AppLayout><Notifications /></AppLayout>} />
          </Route>
        
      </Routes> 
    
       
    </>
  );
}


export default Router;

import React, { useEffect, useState } from 'react';
import { UserIcon, ClockIcon } from '@heroicons/react/24/outline';
import { CircleStackIcon, FaceSmileIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import LineChart from '../components/LineChart';
import { useParams } from 'react-router-dom';
import { getPlayer } from '../services/PlayerService';
import { useSelector } from 'react-redux';
import {convertReadableTime} from "../utils/date"
import {toast} from "react-hot-toast"
import NotFound from './NotFound';
export default function PlayerDetail() {
  const { id: documentName } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [found,setFound]=useState(true);
 const [player,setPlayer]=useState({});
  const playerData = async () => {
    const result = await getPlayer(user, documentName);
    if(result){
      setPlayer(result);
    }else{
      setFound(false);
    toast.error("Kullanıcı bilgileri bulunamadı!")
    }
  };
  useEffect(() => {
    playerData();
  },[])

  if(!found){
    return <NotFound/>
  }
  const data = {
    labels: [0, 3, 6, 9, 12, 15, 18, 21, 24], //saat alt taraf
    datasets: [
      {
        label: 'Bölüm / Harcadığı Süre Saat',
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9], // bölüm sol taraf
        backgroundColor: 'aqua',
        borderColor: 'black',
        pointBorderColor: 'aqua',
        fill: true,
        tension: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      // yAxes: {
      //   min:1,
      //   max:10,
      //   ticks:{stepSize:1,
      //   callback:(value) => "Bölüm"}
      // },
    },
  };
  return (
    <>{found &&  <div className='m-4'>
      <div class="flex items-center space-x-4">
        <img class="w-10 h-10 rounded-full"  
        
        src={player?.avatarImage}
        alt=''
        onerror="this.src='https://images.pexels.com/photos/159868/lost-cat-tree-sign-fun-159868.jpeg'"
        
      />
        <div class="font-medium dark:text-white">
            <div>{player?.username}</div>
            <div class="text-sm text-gray-500 dark:text-gray-400"></div>
        </div>
    </div>

    <div class='flex flex-col md:flex-row gap-x-4'>
      <div>
      
<ol class="space-y-4 w-72">
  <h2 className="text-red-900"></h2>
<li>
    <div class="w-full p-4 text-green-700 border border-green-300 rounded-lg bg-green-50 " role="alert">
        <div class="flex items-center justify-between">
            <span class="sr-only">Oyunu Açtı</span>
            <h3 class="font-medium">1. Oyunu Açtı</h3>
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
        </div>
    </div>
</li>
<li>
    <div class="w-full p-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-green-100 dark:border-green-800 dark:text-green-400" role="alert">
        <div class="flex items-center justify-between">
            <span class="sr-only">Bilgilerini Güncelledi</span>
            <h3 class="font-medium">2. Bilgilerini Güncelledi</h3>
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
        </div>
    </div>
</li>
<li>
    <div class="w-full p-4 text-blue-700 bg-blue-100 border border-blue-300 rounded-lg dark:bg-gray-800 dark:border-blue-800 dark:text-blue-400" role="alert">
        <div class="flex items-center justify-between">
            <span class="sr-only">Araba Yarışı Oynuyor</span>
            <h3 class="font-medium">3. Araba Yarışı Oynuyor</h3>
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        </div>
    </div>
</li>
{/* <li>
    <div class="w-full p-4 text-gray-900 bg-gray-100 border border-gray-300 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400" role="alert">
        <div class="flex items-center justify-between">
            <span class="sr-only">Review</span>
            <h3 class="font-medium">4. Review</h3>
        </div>
    </div>
</li>
    <li>
    <div class="w-full p-4 text-gray-900 bg-gray-100 border border-gray-300 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400" role="alert">
        <div class="flex items-center justify-between">
            <span class="sr-only">Confirmation</span>
            <h3 class="font-medium">5. Confirmation</h3>
        </div>
    </div>
</li> */}
</ol>

      </div>
      <div class='w-full md:w-1/3 '>
        <div class='flex flex-col justify-center items-center p-4'>
          <div className='flex-1'>
            <UserIcon
              className='flex-1 h-24 w-24 text-black '
              aria-hidden='true'
            />
          </div>
          <div className='flex-1'>
            <span className='text-xl text-gray-800'>Bulunduğu Bölüm</span>
          </div>
          <div className='flex-1 py-5'>
            <span className='text-4xl font-medium'>{player["Current Level"] ? player["Current Level"] : "Belirtilmemiş"}</span>
          </div>
        </div>
      </div>
      <div class='w-full md:w-1/3 '>
        <div class='flex flex-col justify-center items-center p-4'>
          <div className='flex-1'>
            <ClockIcon
              className='flex-1 h-24 w-24 text-black '
              aria-hidden='true'
            />
          </div>
          <div className='flex-1'>
            <span className='text-xl text-gray-800'>
              Oyunda Geçirdiği Zaman
            </span>
          </div>
          <div className='flex-1 py-5'>
            <span className='text-4xl font-medium'>{player['Time spent in game'] ? convertReadableTime(player['Time spent in game']) : "Belirtilmemiş"}</span>{' '}
          </div>
        </div>
      </div>
    </div>
    <div className='flex flex-col md:flex-row mt-5'>
      <div class='w-full md:w-1/6  rounded'>
        <div class='flex flex-col justify-center items-center p-4'>
          <div className='flex-1'>
            <CircleStackIcon
              className='flex-1 h-24 w-24 text-orange-500 '
              aria-hidden='true'
            />
          </div>
          <div className='flex-1'>
            <span className='text-xl text-orange-500'>Para</span>{' '}
          </div>
          <div className='flex-1 py-5'>
            <span className={`${player.Money ? "text-4xl font-medium" : "text-2xl"} text-orange-600`}>
             {player?.Money ?? "Belirtilmemiş"}
            </span>
          </div>
        </div>
      </div>
      <div class='w-full md:w-1/6  rounded'>
        <div class='flex flex-col justify-center items-center p-4'>
          <div className='flex-1'>
            <FaceSmileIcon
              className='flex-1 h-24 w-24 text-green-500 '
              aria-hidden='true'
            />
          </div>
          <div className='flex-1'>
            <span className='text-xl text-green-500'>Mutluluk</span>
          </div>
          <div className='flex-1 py-5'>
            <span className={`${player.Happiness ? "text-4xl font-medium" : "text-2xl"} text-green-600`}>{player?.Happiness ?? "Belirtilmemiş"}</span>
          </div>
        </div>
      </div>
      <div class='w-full md:w-4/6  rounded'>
        <div class='flex flex-col justify-center items-center p-4'>
          <h2 className="text-red-900">Grafik yapım aşamasında</h2>
          <LineChart data={data} options={options} />
        </div>
      </div>
    </div>
  </div> }
     
    </>
  );
}

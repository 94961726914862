import axios from 'axios'
import { collection, doc, endAt, getDoc, getDocs, limit, orderBy, query, startAt, where } from 'firebase/firestore';
import { db } from '../utils/firebase';


export const getAllPlayers = async (user) => {



  // const url = '/api/process/read/all';
  // const headers = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ` + user.accessToken,
  //   },
  // };
  // const data = { source: 'firebase', collectionName: 'players' };
  // const result = await axios.post(url, data, headers);
  // return result.data;
};

export const getPlayers = async (user, page, lmt) => {
  // const baseUrl = "https://api.ozmonunmaceradunyasi.com"
  // const url = "/api/process/read/all";
  // const headers = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ` + user.accessToken,
  //   }
  // }

  const defaultLimit = 20;
  // * page ve limit - olma durumunu ele alındı.
  page < 0 || isNaN(page) || typeof page == "string" || typeof page == "boolean" ? page = 1 : page = page;
  if (lmt < 0) lmt = defaultLimit;

  // page--> 1 start 0 - end 20
  // page --> 2 start 20 - end 40
  // page --> 3 start 40 - end 60
  const startValue = (page === 1 ? 0 : (page - 1) * lmt);
  const endValue = page * lmt;
  // console.log('startValue', startValue)
  // console.log('endValue', endValue)
  // console.log('page :>> ', page);

  console.log("startValue", startValue);
  console.log("endValue", endValue)

  // const db.collection('players').get();

  // const newData = snapshot.docs.map(doc => ({
  //   id: doc.id,
  //   ...doc.data()
  // }));


  //const q = query(collection(db, "players"),startAt(startValue),endAt(endValue));
  const q = query(collection(db, "players"), orderBy("time"), startAt(startValue), limit(lmt));


  const querySnapshot = await getDocs(q);

  console.log("qs", querySnapshot)
  let players = []
  querySnapshot.forEach((doc) => {
    players.push({ data: doc.data(), documentName: doc.id })
  });

  console.log("players", players)

  // const data = {source:'firebase',collectionName:'players',startValue,endValue};
  // const result = await axios.post(baseUrl+url,data,headers);
  return { data: players, status: 200 };


}

export const getPlayer = async (user, documentName) => {

  try {
  const docRef = doc(db, "players", documentName);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }

  //   const url = '/api/process/read/';
  //   const headers = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ` + user.accessToken,
  //     },
  //   };
  //   const data = {
  //     source:"firebase",
  //     collectionName:"playerData",
  //     documentName  
  // }
  // const result = await axios.post(url,data,headers);
  // const data = {
  //   source: "firebase",
  //   collectionName: "playerData",
  //   documentName
  // };
  // try {
  //   const url = "https://api.ozmonunmaceradunyasi.com/api/process/read";
  //   const result = await axios.post(url, data, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ` + user.accessToken,
  //     }
  //   })
  //   return result.data;
} catch (err) {
  return err.response.data;
}

}
import { ArrowDownIcon, ArrowUpIcon, ChartBarIcon } from '@heroicons/react/20/solid';
import {
  UserIcon,
  ClockIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/outline';
import { getTotalPlayer, getTimeInGame, getParts, getPopupPressStat } from '../services/StatService';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { convertReadableTime } from "../utils/date"
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Stats() {
  const { user } = useSelector((state) => state.auth);
  const [totalPlayerCount, setTotalPlayerCount] = useState(0);
  const [popupPressCount, setPopupPressCount] = useState(0);
  const [popupReturnCount, setPopupReturnCount] = useState(0);
  const [timeInGame, setTimeInGame] = useState(0);
  const [levelName, setLevelName] = useState("");

  const totalPlayer = async () => {
    const totalCount = await getTotalPlayer(user);
    console.log("total", totalCount)
    setTotalPlayerCount(totalCount.count);
  };

  const getPopupPress = async () => {
    const res = await getPopupPressStat(user);
    console.log("total", res)
    setPopupPressCount(res.PopUpPress);
    setPopupReturnCount(res.PopUpReturn);
  };

  const timeInGameCalculate = async () => {
    const { timepassedinminutes } = await getTimeInGame(user)
    const totalMinutes = timepassedinminutes.reduce((accumulator, currentValue) => {
      return parseInt(accumulator) + parseInt(currentValue);
    });
    const totalMilliseconds = totalMinutes * 60 * 1000;

    setTimeInGame(totalMilliseconds);

  };
  const hardestPart = async () => {
    const data = await getParts(user);
    console.log("data", data)
    const entries = Object.entries(data);

    // Filter out the time property as we don't want to include it in sorting
    const filteredEntries = entries.filter(([key, value]) => key !== 'time');

    // Sort the array based on the values
    const sortedEntries = filteredEntries.sort((a, b) => Number(a[1]) - Number(b[1]));

    // Extract the keys from the sorted array
    const sortedKeys = sortedEntries.map(entry => entry[0]);

    console.log("sortedKeys", sortedKeys)

    setLevelName(sortedKeys[0])

  }

  const convertPartEntoTr = async (text) => {
    if (text === "Level 1") {
      setLevelName("1. Bölüm ")
      return;
    } else if (text === "Level 2") {
      setLevelName("2. Bölüm")
      return;
    } else if (text === "Level 3") {
      setLevelName("3. Bölüm")
      return;
    } else if (text === "Level 4") {
      setLevelName("4. Bölüm")
      return;
    } else if (text === "Level 5") {
      setLevelName("5. Bölüm")
      return;
    }
    return levelName;
  }
  useEffect(() => {
    totalPlayer();
    timeInGameCalculate()
    hardestPart()
    getPopupPress();

  }, []);

  const statistics = [
    {
      id: 1,
      name: 'Toplam Oyuncu Sayısı',
      stat: totalPlayerCount,
      icon: UserIcon,
      change: '',
      changeType: '',
    },
   
    // {
    //   id: 2,
    //   name: 'Oyunda Geçirilen Süre',
    //   stat: convertReadableTime(timeInGame),
    //   icon: ClockIcon,
    //   change: '5.4%',
    //   changeType: 'increase',
    // },
    { id: 3, name: 'En Zor Bölüm', stat: levelName, icon: ClockIcon },
    {
      id: 4,
      name: 'Popup Tıklanma Sayısı',
      stat: popupPressCount,
      icon: ChartBarIcon,
      change: '',
      changeType: '',
    },
    {
      id: 5,
      name: 'Popup Oyuna Dönme Sayısı',
      stat: popupReturnCount,
      icon: ChartBarIcon,
      change: '',
      changeType: '',
    },
    // { id: 4, name: '', stat: '58.16%', icon: ArrowLongRightIcon },
  ];

  return (
    <div>
      {/* <h3 className='text-lg font-medium leading-6 text-gray-900'>
        Last 30 days
      </h3> */}

      <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2'>
        {statistics && statistics.map((item) => (
          <div
            key={item.id}
            className='relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6'
          >
            <dt>
              <div className='absolute rounded-md bg-indigo-500 p-3'>
                <item.icon className='h-6 w-6 text-white' aria-hidden='true' />
              </div>
              <p className='ml-16 truncate text-sm font-medium text-gray-500'>
                {item.name}
              </p>
            </dt>
            <dd className='ml-16 flex items-baseline pb-6 sm:pb-7'>
              <p className='text-2xl font-semibold text-gray-900'>
                {totalPlayerCount == 0 ? "Yükleniyor..." : 
                Number.isInteger(item.stat) ? Number((item.stat))
                  .toFixed(2)
                  .replace(".", ",")
                  .replace(/\d(?=(\d{3})+\,)/g, "$&.").split(',')[0] : item.stat}
              </p>


              {/* <div className='absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6'>
                <div className='text-sm'>
                  <a
                    href='#'
                    className='font-medium text-indigo-600 hover:text-indigo-500'
                  >
                    View all<span className='sr-only'> {item.name} stats</span>
                  </a>
                </div>
              </div> */}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

import axios from 'axios'
import { addDoc, collection, deleteDoc, doc, endAt, getDoc, getDocs, limit, orderBy, query, startAt, where } from 'firebase/firestore';
import { db } from '../utils/firebase';

export const getNotifications = async (user, page, lmt) => {
  const q = query(collection(db, "notifications"), orderBy("publishTime",'desc'));
  const querySnapshot = await getDocs(q);
  console.log("qs", querySnapshot)
  let notifications = []
  querySnapshot.forEach((doc) => {
    notifications.push({ data: doc.data(), documentName: doc.id })
  });
  return { data: notifications, status: 200 };
}

export const addNotification = async (title, text, time) => {
    addDoc(collection(db, "notifications"), {
      publishTime: time,
      title: title,
      body: text,
      status: "waiting"
  }).then(() => {
    console.log("Document successfully written!");
  })
  .catch((error) => {
    console.error("Error writing document: ", error);
  });
}

export const removeNotification = async (documentName) => {
  await deleteDoc(doc(db, "notifications", documentName));
}
import React, { useEffect, useState } from 'react';
import { getGamesDurations, getItems, getParts, getTimeInGame } from '../services/StatService';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js"
import DatePicker from "../components/DatePicker"
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { db } from '../utils/firebase';
import { tr } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';

// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

ChartJS.register(BarElement)
function Reports() {
  const { user } = useSelector((state) => state.auth);
  const [reports, setReports] = useState([]);

  const [reports2, setReports2] = useState(false);
  const [reports3, setReports3] = useState(false);
  const [data, setData] = useState([]);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const getReportsOfParts = async () => {
    // const result = await getParts(user);
    // setReports(result.data);
    // const result2 = await getGamesDurations(user);
    // setReports2(result2.data);
    const result3 = await getItems(user);
    setReports3(result3.data);
  };

  const getReportsFirebase = async () => {
    // setData([]);
    setReports2({});
    // var d = new Date(selectedFirstDate)
    // d.setHours(0, 0, 0, 0);
    // var d2 = new Date(selectedLastDate)
    // d2.setHours(23, 59, 59, 0);
    let startDate = selectionRange.startDate;
    let endDate = selectionRange.endDate;

    
    console.log('startDate - '+startDate+' - endDate - '+endDate)

    startDate = new Date(new Date(startDate).getTime() + 60 * 60 * 24 * 1000);
    endDate = new Date(new Date(endDate).getTime() + 60 * 60 * 24 * 1000);

    if (new Date(selectionRange.startDate).getTime() === new Date(selectionRange.endDate).getTime()) {
      endDate = new Date(new Date(endDate).getTime() + (60 * 60 * 24 * 1000));
    }
    else {
      endDate = new Date(new Date(endDate).getTime() + (60 * 60 * 24 * 1000));
    }

    console.log('startDate2 - '+startDate+' - endDate2 - '+endDate)

    const q = query(collection(db, "report_miniGameSessions"), where('timestamp', '>=', startDate), where('timestamp', '<', endDate));

    const querySnapshot = await getDocs(q);
    console.log("querySnapshot",querySnapshot)

    let result = {};

    if (querySnapshot?.docs?.length > 0) {
      querySnapshot.forEach((doc) => {
        let newResult = doc.data().data;

        console.log("newResult", newResult)
        console.log("result", result)

        if (result?.miniGameDurations || result?.miniGameInteractions) {
          if(result?.miniGameDurations) {
            result?.miniGameDurations.map((t, k) => {
              if (newResult.miniGameDurations) {
                result.miniGameDurations[k] = result.miniGameDurations[k] + newResult.miniGameDurations[k];
              }
            })
          }
          if(result?.miniGameInteractions) {
            result?.miniGameInteractions.map((t, k) => {
              if (newResult.miniGameInteractions) {
                result.miniGameInteractions[k] = result.miniGameInteractions[k] + newResult.miniGameInteractions[k];
              }
            })
          }
          
        }
        else {
          result = newResult
        }

        // setData([...data, newResult])

        setReports2(result);
      });
    }
  }

  useEffect(() => {
    getReportsFirebase();
    getReportsOfParts();
  }, []);


  useEffect(() => {
    console.log('data', data)
  }, [data]);

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const renderChart = () => {
    const levelNames = reports.levelname?.map((level, index) => `${index + 1}. Bölüm`);
    const playerCounts = reports.playercount;

    const data = {
      labels: levelNames,
      datasets: [
        {
          label: 'Oynayan Kişi Sayısı',
          data: playerCounts,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    };

    return <Bar data={data} options={config} />;
  };


  const renderChart2 = () => {
    const levelNames = reports2.miniGameNames?.map((level, index) => level);
    const playerCounts = reports2.miniGameDurations?.map((time, index) => (time));

    const data = {
      labels: levelNames,
      datasets: [
        {
          label: 'Hangi Oyunda Ne Kadar Süre Geçirildi?',
          data: playerCounts,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    };

    return <Bar data={data} options={config} />;
  };

  const renderChart3 = () => {
    const levelNames = reports2.miniGameNames?.map((level, index) => level);
    const playerCounts = reports2.miniGameInteractions?.map((time, index) => (time));

    const data = {
      labels: levelNames,
      datasets: [
        {
          label: 'Hangi Oyun Kaç Kez Oynanıldı?',
          data: playerCounts,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    };

    return <Bar data={data} options={config} />;
  };

  const handleSelect = (date) => {
    setSelectionRange(date.selection)
  }

  useEffect(() => {

    getReportsFirebase();
    console.log('selectionRange', selectionRange)
  }, [selectionRange])

  return (
    <>
      <div className=" mb-2">
        <div className='flex justify-between'>
          <h2 className="text-3xl font-semibold text-black mb-10">Günlük Raporlar</h2>

          <div className="flex justify-start">
            <div className=" calendar ">

              {/* <input
                type="date"
                className="p-2 w-full rounded border-[1.5px] border-stroke"
                onChange={(e) => { setSelectedFirstDate(e.target.value) }}
                value={selectedFirstDate}
              /> */}
            </div>
            {/* <div className=" calendar ml-5">
              <label className="text-sm font-semibold">Bitiş</label>
              <br />
              <DatePicker
                selected={selectedLastDate}
                onChange={(date) => setSelectedLastDate(date)}
                dateFormat="dd/MM/yyyy"
                className="p-2 w-full"
                locale={"tr"}
                onBeforeInput={(e) => {
                  e.preventDefault();
                }}
              />



              {/* <input
                type="date"
                className="p-2 w-full rounded border-[1.5px] border-stroke"
                onChange={(e) => { setSelectedLastDate(e.target.value) }}
                value={selectedLastDate}
              /> 
            </div> */}
          </div>
          {/* 
          <div className='grid grid-cols-2 gap-4 place-content-center w-80'>

            <input
              type="date"
              className="custom-input-date custom-input-date-1 w-56 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              onChange={(e) => { setSelectedDate(e.target.value) }}
              value={(String(convertDateString(selectedFirstDate)))}
            />
          </div> */}

        </div>

        {reports?.miniGameNames?.length > 0 ? renderChart() : null}
      </div>

      <div className="px-2">
        <div className='grid grid-cols-2 gap-4 place-content-center w-80'>
        </div>

        <p className='mb-5'>Aşağıdaki grafik, saniye bazında geçirilen süreleri göstermektedir. (9 Eylül 2024 itibarıyla verilere ulaşabilirsiniz.)</p>
        <p></p>

        <div>


        </div>

        <div style={{ width: "30%", float: "right", marginLeft: "10px" }}>
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
            locale={tr}
          />
        </div>

        <div style={{ width: "60%", float: "left" }}>
          {reports2?.miniGameNames?.length > 0 ? renderChart2() : null}
        </div>

       

        <div style={{ width: "60%", float: "left", clear: "both", marginTop: "30px" }} className='mt-20'>
          {reports2?.miniGameNames?.length > 0 ? renderChart3() : null}
        </div>
      </div>

      <div className="mt-20">
        {/* <table class="min-w-full bg-white border border-gray-300">
                    <thead  class="bg-gray-200 text-black">
                        <tr>
                            <th className="py-2 px-4 border-b border-gray-300" style={{textWrap: "noWrap"}}>DATE</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {reports2 && reports2.map((d => (
                            <tr className="bg-white hover:bg-gray-100">
                                <td className="py-2 px-4 border-b border-gray-300" style={{textWrap: "noWrap"}}>{formatDate(d.createdAt)}</td>
                                
                            </tr>
                        )))} 
                    </tbody>
                </table> */}
      </div>
    </>
  );
}

export default Reports;

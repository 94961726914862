import React from 'react';
import PieChart from '../components/PieChart';
import HorizontalBarChart from '../components/HorizontalBarChart';
import DatePicker from '../components/DatePicker';
export default function DemographicStatics() {
  return (
    <div>
      <h2 className='text-3xl p-4'>Demografik Bilgiler</h2>
      <div className='flex gap-x-4  mx-4'>
        <DatePicker />
        <DatePicker />
      </div>

      <div className='flex justify-center px-4'>
        <div class='w-full h-full md:w-3/6  rounded x'>
          <div class='flex-1  justify-center h-96'>
            <PieChart data={'data'} options={'options'} />
          </div>
        </div>

        <div class='w-full  md:w-3/6  rounded'>
          <div class='flex-1  justify-center h-full'>
            <HorizontalBarChart data={'data'} options={'options'} />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import LineChart from "../components/LineChart"
export default function DepartmentStatics() {
    const data = {
        labels:[0,3,6,9,12,15,18,21,24], //saat alt taraf
        datasets:[{
          label:'Kullanıcı Sayısı',
          data: [1,2,3,4,5,6,7,8,9], // bölüm sol taraf
          backgroundColor:"aqua",
          borderColor:'black',
          pointBorderColor:'aqua',
          fill:true,
          tension:1
        }]
      }
    
      const options = {
        responsive: true,
        scales: {
       
        // yAxes: {
            
        //   min:1,
        //   max:10,
        //   ticks:{stepSize:1,
        //   callback:(value) => "Bölüm"}
        // },
    
        }
    }
  return (
    <div>
        <h2 className="text-3xl p-4">Oyun Bölümlerine Göre İstatistikler</h2>
        <div class='w-full md:w-5/6  rounded'>
            <div class='flex flex-col justify-center items-center p-4'>
                <LineChart data={data} options={options}/>
             
            </div>
          </div>
    </div>
  )
}

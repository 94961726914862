import React from 'react'
import DatePicker from "../components/DatePicker";
import LineChart from "../components/LineChart";
export default function UsageStatics() {
  const data = {
    labels:[0,3,6,9,12,15,18,21,24], //saat alt taraf
    datasets:[{
      label:'Kullanım İstatistikleri',
      data: [1,7,5,4,2,6,8,8,9], // bölüm sol taraf
      backgroundColor:"aqua",
      borderColor:'black',
      pointBorderColor:'aqua',
      fill:true,
      tension:1
    }]
  }

  const options = {
    responsive: true,
    scales: {
   
    // yAxes: {
        
    //   min:1,
    //   max:10,
    //   ticks:{stepSize:1,
    //   callback:(value) => "Bölüm"}
    // },

    }
}
  return (
    <div> 
      <h2 className='text-3xl p-4'>Kullanım İstatistikleri</h2>
      <div className="flex gap-x-4 justify-end  mx-4">
    <DatePicker/>
    <DatePicker/>
    </div>
    <div className='flex justify-center px-4'>
        <div class='w-full '>
          <LineChart data={data} options={options}/>
        </div>

       
      </div>
    </div>
  )
}

import { useState } from 'react';
import toast from 'react-hot-toast';
import { changeRole, changePassword } from '../../services/ManagerService';
import { useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom"
function EditManagerForm({ setOpen, open, person }) {
  const navigate=useNavigate();
  const [userTypeId, setUserTypeId] = useState(person.userTypeId);
  const [password, setPassword] = useState('');
  const { user } = useSelector((state) => state.auth);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const documentName = person.documentName;
    const role = userTypeId;
    const email = person.email;
    if (password.length > 0 && userTypeId.length > 0) {
      try {
        const [passwordResult, roleResult] = await Promise.all([
          changeRole(user, documentName, role),
          changePassword(user, email, password),
        ]);

        setOpen(false);
        toast.success('Şifre ve Rol başarıyla değiştirildi!');
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    } else if (password.length > 0 || userTypeId.length > 0) {
      if (password.length > 0) {
        try {
          changePassword(user, email, password);
          setOpen(false);
         
          toast.success('Şifre başarıyla değiştirildi!');
        } catch (err) {
          toast.error(err?.response?.data?.message);
        }
      } else {
        try {
          changeRole(user, documentName, role);
          setOpen(false);
          toast.success(`Yetki ${userTypeId=="rVTSEqPlPqgh5vADeqhc" ? "Yönetici" : "Marketing Reporter"} olarak değiştirildi!`);
          window.location.reload();

        } catch (err) {
          toast.error(err?.response?.data?.message);
        }
      }
    } else {
      return toast.error('Değiştirecek herhangi bir veri girmediniz.');
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className='space-y-8 divide-y divide-gray-200'
      >
        <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
          <div className='space-y-6 sm:space-y-5'>
            <div>
              <h3 className='text-base text-center font-bold leading-6 text-gray-900'>
                Yönetici Düzenle
              </h3>
            </div>
          </div>

          <div className='space-y-6 pt-3 sm:space-y-5 sm:pt-4'>
            <div className='space-y-6 sm:space-y-5'>
              {/* <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5'>
                <label
                  htmlFor='first-name'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  Ad
                </label>
                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <input
                    type='text'
                    name='first-name'
                    id='first-name'
                    value={person.name}
                    autoComplete='given-name'
                    className='block w-full px-2 max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  />
                </div>
              </div> */}

              {/* <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label
                  htmlFor='last-name'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  Soyad
                </label>
                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <input
                    type='text'
                    name='last-name'
                    id='last-name'
                    value={person.title}
                    autoComplete='family-name'
                    className='block w-full px-2  max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  />
                </div>
              </div> */}
              <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  Şifre
                </label>
                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <input
                    type='password'
                    name='password'
                    id='password'
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete='password-name'
                    className='block w-full px-2 max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='pt-6 sm:pt-5'>
            <div role='group' aria-labelledby='label-email'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4'>
                <div>
                  <div
                    className='text-sm font-semibold leading-6 text-gray-900'
                    id='add-manager'
                  >
                    Yönetici Rolü
                  </div>
                </div>
                <div className='max-w-lg space-y-4'>
                  <div className='relative flex items-start'>
                    <div className='flex h-6 items-center'>
                      <input
                        id='manager'
                        name='user-type'
                        type='radio'
                        value='rVTSEqPlPqgh5vADeqhc'
                        onChange={(e) => setUserTypeId(e.target.value)}
                        checked={userTypeId === 'rVTSEqPlPqgh5vADeqhc'}
                        className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                      />
                    </div>
                    <div className='ml-3 text-sm leading-6'>
                      <label
                        htmlFor='manager'
                        className='font-medium text-gray-900'
                      >
                        Yönetici
                      </label>
                    </div>
                  </div>

                  <div className='relative flex items-start'>
                    <div className='flex h-6 w-6 items-center'>
                      <input
                        id='marketing-reporter'
                        name='user-type'
                        type='radio'
                        value='sQc4rDUPAHzqwiph4SPx'
                        onChange={(e) => setUserTypeId(e.target.value)}
                        checked={userTypeId === 'sQc4rDUPAHzqwiph4SPx'}
                        className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                      />
                    </div>
                    <div className='ml-3 text-sm leading-6'>
                      <div className='flex items-center'>
                        <label
                          htmlFor='marketing-reporter'
                          className='font-medium text-gray-900'
                        >
                          Marketing Raportör
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-5 sm:mt-6'>
          <button
            type='submit'
            className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Düzenle
          </button>
        </div>
      </form>
    </>
  );
}

export default EditManagerForm;

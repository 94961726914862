import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3CenterLeftIcon,
  DocumentChartBarIcon,
  HomeIcon,
  GlobeAmericasIcon,
  UsersIcon,
  UserPlusIcon,
  PuzzlePieceIcon,
  XMarkIcon,
  ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/outline';

import Sidebar from '../components/Sidebar';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../store/auth';
import {logout as logoutHandle} from "../utils/firebase";

const navigation = [
  {
    name: 'Anasayfa',
    href: '/',
    icon: HomeIcon,
    current: window.location.pathname === '/' ? true : false,
  },
  {
    name: 'Oyuncular',
    href: '/players',
    icon: UsersIcon,
    current: window.location.href.indexOf('/players') !== -1 ? true : false,
  },
  {
    name: 'Raporlar',
    href: '/reports',
    icon: DocumentChartBarIcon,
    current: window.location.href.indexOf('/reports') !== -1 ? true : false,
  },
  {
    name: 'Oyun Ayarları',
    href: '/game-settings',
    icon: GlobeAmericasIcon,
    current: window.location.href.indexOf('/game-settings') !== -1 ? true : false,
  },
  {
    name: 'Yöneticiler',
    href: '/managers',
    icon: UserPlusIcon,
    current: window.location.href.indexOf('/managers') !== -1 ? true : false,
  },
  {
    name: 'Oyun Parametreleri',
    href: '/game-parameters',
    icon: PuzzlePieceIcon,
    current: window.location.href.indexOf('/game-parameters') !== -1 ? true : false,
  }
];

function AppLayout({ children }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [filteredNavigation, setFilteredNavigation] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleLogout = () => {
    
    const logoutCheck= dispatch(logout());
    logoutHandle()
    if(logoutCheck){
      navigate('/login',{replace:true})
      
    }
   
}

  useEffect(() => {
    const role = localStorage.getItem('role');

    console.log("navigation", navigation)

    if (role === 'marketingReporter') {
      setFilteredNavigation(
        navigation.filter((item) => item.name === 'Anasayfa' || item.name === 'Raporlar')
      );
    } else if (role === 'admin') {
      setFilteredNavigation(navigation);
    }
  }, []);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as='div' className='relative z-40 lg:hidden' onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 z-40 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-cyan-700 pt-5 pb-4'>
              <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className='flex flex-shrink-0 items-center px-4'>
                    <img className='h-8 w-auto' src='/logo.png' alt='Easywire logo' />
                  </div>
                  <nav
                    className='mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto'
                    aria-label='Sidebar'
                  >
                    <div className='space-y-1 px-2'>
                      {filteredNavigation.map((item) => console.log("item", item) && (
                        <>
                        asdasd{item.href}
                        {item.href == "/logout" ? 
                        <a
                        key={item.name}
                        onClick={handleLogout}
                        className={classNames(
                          item.current
                            ? 'bg-cyan-800 text-white'
                            : 'text-cyan-100 hover:text-white hover:bg-cyan-600',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <item.icon
                          className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'
                          aria-hidden='true'
                        />
                        {item.name}
                      </a>
                        : 
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-cyan-800 text-white'
                              : 'text-cyan-100 hover:text-white hover:bg-cyan-600',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'
                            aria-hidden='true'
                          />
                          {item.name}
                        </a>
                        }
                        </>
                      ))}
                    </div>
                    <div className='mt-6 pt-6'></div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Sidebar />
        <div className='flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none'>
          <button
            type='button'
            className='border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <Bars3CenterLeftIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='flex flex-1 flex-col lg:pl-64 mx-10 my-10'>{children}</div>
      </>
    );
  }

  export default AppLayout;
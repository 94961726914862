import React from 'react'

const DefaultLayout = ({children}) => {
  return (
    <div>
        {children}

    </div>
  )
}

export default DefaultLayout

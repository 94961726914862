import React from 'react';
import DatePicker from 'react-date-picker';

export default function Date({value,onChange}) {
  
  return (
    <div>
      <DatePicker onChange={onChange} value={value} />
      
    </div>
  );
}
import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotFound from '../components/NotFound';
const RequireAuth = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const role = localStorage.getItem('role');
  const storedUser = localStorage.getItem('user_omd');

  const allowedRoutes = {
    admin: 'all',
    marketingReporter: ['/', '/reports'],
  };

  // Eğer kullanıcı giriş yapmışsa ve /login sayfasına erişmeye çalışırsa, ana sayfaya yönlendir.
  if (location.pathname === '/login') {
    return user ? <Navigate to="/" replace /> : <Outlet />;
  }

  // Kullanıcı oturum açmamışsa, /login sayfasına yönlendir.
  if (!storedUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const isAllowed = allowedRoutes[role] === 'all' ? true : allowedRoutes[role]?.includes(location.pathname);

  // Eğer izin verilmeyen bir sayfaya gitmeye çalışırsa, NotFound bileşenini göster.
  if (role === 'marketingReporter' && !isAllowed) {
    return <NotFound />;
  }

  return (
    // !user || !isAllowed
    //   ? <Navigate to="/login" state={{ from: location }} replace />
    //   : 
      <Outlet />
  );
};

export default RequireAuth;

import { createSlice } from '@reduxjs/toolkit';

const defaultPage = 1;
const defaultLimit = 20;
const initialState = {
  players: [],
  pagination:{page:defaultPage,limit:defaultLimit,totalPlayer:0},
};
const players = createSlice({
  name: 'players',
  initialState,
  reducers: {
    setPlayers: (state, action) => {
      state.players=action.payload
    },
    setPagination : (state,action) => {
      state.pagination=action.payload;
    },
   
  },
});

export const { setPlayers,setPagination } = players.actions;
export default players.reducer;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './input.css';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from "./store";
const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <Router history={history}>
    <App />
  </Router>
  </Provider>
);



import React, { useEffect, useState } from 'react';
import {
  DocumentChartBarIcon,
  HomeIcon,
  GlobeAmericasIcon,
  UsersIcon,
  UserPlusIcon,
  PuzzlePieceIcon,
  ArrowLeftOnRectangleIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline';

import { Disclosure } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../store/auth';
import { logout as logoutHandle } from "../utils/firebase";
import { BellAlertIcon } from '@heroicons/react/20/solid';

function Sidebar() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const navigation = [
    { name: 'Anasayfa', href: '/', icon: HomeIcon, current: window.location.pathname == "/" ? true : false },
    // {
    //   name: 'Oyuncular',
    //   href: '/players',
    //   icon: UsersIcon,
    //   current: window.location.href.indexOf("/players") != -1 ? true : false
    // },
    {
      name: 'Canlı Raporlar',
      href: '/reports-live',
      icon: DocumentChartBarIcon,
      // children: [
      //   { name: 'Bölüm İstatistiği', href: '/reports/department',current:false },
      //   { name: 'Demografik Bilgiler', href: '/reports/demographic',current:false },
      //   { name: 'Kullanım İstatistikleri', href: '/reports/usage',current:false },
      // ],
      current: window.location.pathname == "/reports-live" ? true : false,
    },
    {
      name: 'Günlük Raporlar',
      href: '/reports',
      icon: DocumentChartBarIcon,
      // children: [
      //   { name: 'Bölüm İstatistiği', href: '/reports/department',current:false },
      //   { name: 'Demografik Bilgiler', href: '/reports/demographic',current:false },
      //   { name: 'Kullanım İstatistikleri', href: '/reports/usage',current:false },
      // ],
      current: window.location.pathname == "/reports" ? true : false,
    },
    {
      name: 'Satın Alınan Eşyalar',
      href: '/items',
      icon: CurrencyDollarIcon,
      // children: [
      //   { name: 'Bölüm İstatistiği', href: '/reports/department',current:false },
      //   { name: 'Demografik Bilgiler', href: '/reports/demographic',current:false },
      //   { name: 'Kullanım İstatistikleri', href: '/reports/usage',current:false },
      // ],
      current: window.location.pathname == "/items" ? true : false,
    },
    {
      name: 'Bildirim Yönetimi',
      href: '/notifications',
      icon: BellAlertIcon,
      // children: [
      //   { name: 'Bölüm İstatistiği', href: '/reports/department',current:false },
      //   { name: 'Demografik Bilgiler', href: '/reports/demographic',current:false },
      //   { name: 'Kullanım İstatistikleri', href: '/reports/usage',current:false },
      // ],
      current: window.location.pathname == "/notifications" ? true : false,
    },
    // {
    //   name: 'Oyun Ayarları',
    //   href: '/game-settings',
    //   icon: GlobeAmericasIcon,
    //   current: window.location.href.indexOf("/game-settings") != -1 ? true : false,
    // },
    // {
    //   name: 'Yöneticiler',
    //   href: '/managers',
    //   icon: UserPlusIcon,
    //   current: window.location.href.indexOf("/managers") != -1 ? true : false
    // },
    // {
    //   name: 'Oyun Parametreleri',
    //   href: '/game-parameters',
    //   icon: PuzzlePieceIcon,
    //   current: window.location.href.indexOf("/game-parameters") != -1 ? true : false
    // },
    {
      name: 'Çıkış Yap',
      href: '/login',
      icon: ArrowLeftOnRectangleIcon,
      current: false,
    },

  ];
  const secondaryNavigation = [
    // { name: 'Settings', href: '#', icon: CogIcon },
    // { name: 'Help', href: '#', icon: QuestionMarkCircleIcon },
    // { name: 'Privacy', href: '#', icon: ShieldCheckIcon },
  ];
  const [filteredNavigation, setFilteredNavigation] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleLogout = () => {

    const logoutCheck = dispatch(logout());
    logoutHandle()
    if (logoutCheck) {
      navigate('/login', { replace: true })

    }

  }

  useEffect(() => {
    const role = localStorage.getItem('role');

    if (role === 'marketingReporter') {
      setFilteredNavigation(
        navigation.filter(
          (item) =>
            item.name === 'Anasayfa' || item.name === 'Raporlar'
        )
      );
    } else if (role === 'admin') {
      setFilteredNavigation(navigation);
    }
  }, []);
  return (
    <>
      {/* Static sidebar for desktop */}
      <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex flex-grow flex-col overflow-y-auto bg-cyan-700 pt-5 pb-4'>
          <div className='flex flex-shrink-0 items-center px-4'>
            <img
              className='h-20 w-auto'
              src='/logo.png'
              alt='Easywire logo'
            />
          </div>
          <nav
            className='mt-5 flex flex-1 flex-col divide-y divide-cyan-800 overflow-y-auto'
            aria-label='Sidebar'
          >
            <div className='space-y-1 px-2'>
              {filteredNavigation.map((item) => {
                return !item.children ? (
                  <div key={item.name}>
                    <>
                      {item.href == "/login" ?
                        <a
                          key={item.name}
                          href={item.href}
                          onClick={handleLogout}
                          className={`group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white ${item.current ? 'bg-cyan-600 hover:text-white' : null}`}

                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 text-cyan-200'
                            aria-hidden='true'
                          />
                          {item.name}
                        </a>
                        :
                        <a
                          key={item.name}
                          href={item.href}
                          className={`group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white ${item.current ? 'bg-cyan-600 hover:text-white' : null}`}
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 text-cyan-200'
                            aria-hidden='true'
                          />
                          {item.name}
                        </a>
                      }
                    </>
                  </div>
                ) : (
                  <Disclosure as='div' key={item.name} className='space-y-1'>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            item.current
                              ? ' bg-cyan-100'
                              : ' text-cyan-200 hover:bg-cyan-600 hover:text-white',
                            'group w-full flex items-center px-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                          )}
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 text-cyan-200'
                            aria-hidden='true'
                          />

                          {item.name}
                          <svg
                            className={classNames(
                              open
                                ? 'text-white rotate-180'
                                : 'text-white',
                              'ml-20 h-5 w-4 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-white'
                            )}
                            viewBox='0 0 20 20'
                            aria-hidden='true'
                          >
                            <path
                              d='M12 2.25a.75.75 0 01.75.75v16.19l6.22-6.22a.75.75 0 111.06 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 111.06-1.06l6.22 6.22V3a.75.75 0 01.75-.75z'
                              fill='currentColor'
                            />
                          </svg>


                        </Disclosure.Button>
                        <Disclosure.Panel className='space-y-1'>
                          {item.children.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              as='a'
                              href={subItem.href}
                              className='group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-cyan-200 hover:bg-gray-50 hover:text-gray-900'
                            >
                              {subItem.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              })}
            </div>
            <div className='mt-6 pt-6'>
              <div className='space-y-1 px-2'>
                {secondaryNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                  >
                    <item.icon
                      className='mr-4 h-6 w-6 text-cyan-200'
                      aria-hidden='true'
                    />
                    {item.name}
                  </a>
                ))}
              </div >
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;